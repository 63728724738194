.menubar {
   box-shadow: 0px 5px 10px 1px rgba(0,0,0,.2);
}

.footerbar {
    box-shadow: 0px -5px 10px 1px rgba(0,0,0,.2);
    height: 70px;
 }



.menubarcontainer {
    width: 100%;
    padding: 0;
    margin:0;
}

.footerbarcontainer {
  width: 100%;
  padding: 0;
  margin:0;
}
.content {
    height: 100vh;
}


.contentrow {
    height: 100%;
    width: 100vw;
}

.leftcol {
    padding: 0;
    padding-top: 90px;
    padding-bottom: 80px;
}

.horizontal-gradient {
  background: linear-gradient(to right, #93c01f, #F5F5F5);
}

@media only screen and (max-width: 575px) {
  .horizontal-gradient {
    background: linear-gradient(to bottom, #93c01f, #F5F5F5);


  }
}


.rightcol {
    padding: 0;
    padding-top: 90px;
    padding-bottom: 80px;


}

.containersettings {
    width: 95%;
}

.containerchart {
    width: 90%;
    height: 80%;
}

.containertable {
    width: 100%;
}

.tablefirstcol {
    font-weight: bold;
}


.formcol {
  max-width: 120px;
}



.selectcol {
  width: 275px;
}

.indicatorcol {
  max-width: 150px;
  min-width: 150px;

}
.preopdatarow {

  margin: 0;
  border: 2px solid #F5F5F5;
  border-radius: 10px;

}

.predictbutton:hover {
  /*background: linear-gradient(to bottom, #808080, #F5F5F5); border-width: 0px; color: white; /* TEST*/

    background-color: #004622!important;  border-color:#004622!important; /* TEST*/
    color: white;
}

.predictbutton:active {
  /*background: linear-gradient(to bottom, #808080, #F5F5F5); border-width: 0px; color: white; /* TEST*/

  background-color: #006633!important;  border-color:#006633!important; /* TEST*/
  color: white;
}

.predictbutton {
   /*background-color: rgba(0,0,0,0);  /* TEST, avant : white*/
   background-color: #006633!important;  border-color:#006633!important; /* TEST*/


 /* background: linear-gradient(to bottom, #F5F5F5, #808080); border-width: 0px; color: white; /* TEST*/



    font-weight: bold;
    height: 80px;
    font-size: 20px;

}

.buttonrow {
    margin-top: 45px;
}

.spinnerrow {
    height: 90vh;
}

.spinner {
    height: 100px;
    width: 100px;
}
.uniquepatientchartrow {
    width: 100%;
    height: 80vh;
    min-height: 700px;


}

.uniquepatienttablerow {
    width: 100%;

}

/* Chrome, Safari, Edge, Opera */
.withoutarrow::-webkit-outer-spin-button,
.withoutarrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.withoutarrow[type=number] {
  -moz-appearance: textfield;
}


/* .recharts-wrapper,.recharts-surface {
    width: 100%!important;
    height: calc(100%)!important;
} */


label{
    vertical-align: middle;
  }

  #languagelabel-1,#languagelabel-2,#languagelabel-3{
    vertical-align: middle!important;
    margin: 0px!important;
    margin-top: 8px!important;
    margin-right:5px!important;
  }
  .navcenter {
      width: 100%;
  }

  #systemlabel-1,#systemlabel-2{
    vertical-align: middle!important;
    margin: 0px!important;
    margin-top: 8px!important;
    margin-right:0px!important;
  }
  .navcenter {
      width: 100%;
  }

  @media (min-width: 992px) {

  .navcenter {
    align-items: center;

  }
}

.custom-tooltip {
    background-color: white;
    padding: 10px;
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #006633;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;

    }
  }
  .form-range::-webkit-slider-thumb:active {
    background-color: #e4fff2;
  }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #006633;
    border-color: transparent;
    border-radius: 1rem;
  }



  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #006633;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none;
    }
  }
  .form-range::-moz-range-thumb:active {
    background-color: #006633;
  }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e6f7e6;
    border-color: transparent;
    border-radius: 1rem;
  }

  .form-range::-moz-range-progress {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #006633;
    border-color: transparent;
    border-radius: 1rem;
  }


  .modal-title {
    width: 100%;
  }


  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #e6f7e6;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #006633;
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #006633;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #006633;
  }


  #custom-switch {
      width: 3rem;
      height: 1.5rem;
      border-color: #006633;

     /* background-color: rgba(0,0,0,0); /* TEST, avant : suppr ligne*/

  }

  #custom-switch:checked {
    background-color: #006633;
    border-color: #006633 ;
  }


  #custom-switch2 {
    width: 3rem;
    height: 1.5rem;
    border-color: #0E4868;

   /* background-color: rgba(0,0,0,0); /* TEST, avant : suppr ligne*/

}

#custom-switch2:checked {
  background-color: #0E4868;
  border-color: #0E4868 ;
}




  .numberinput {

    border-radius: 100px;
     /*background-color: rgba(0,0,0,0); /* TEST, avant : suppr ligne*/
    border-color: #006633;
  }

  .numberinput:focus {

    border-radius: 100px;
    /*background-color: rgba(0,0,0,0); /* TEST, avant : suppr ligne*/
    border-color: #006633;
  }

  .selectinput {

    border-radius: 100px;
     /*background-color: rgba(0,0,0,0); /* TEST, avant : suppr ligne*/

    border-color: #006633;
  }


  .unit {
    margin-bottom: -10px;
  }
  .openchartlink {
      cursor: pointer;
  }

  .openchartlink:hover {
    text-decoration: underline;


}



table {
  color: #006633!important;

}




.sophialogo {
    height: 70px;
    margin-left: 10px;
  }

  @media only screen and (max-width: 500px) {
    .sophialogo {
      height: 40px;
      margin-left: 5px;


    }
  }

  .mainbrand {
    flex-direction: row;

  }

#sendmessagelink {
  text-decoration: underline!important;
}

.list-group{
  max-width: 100%;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0,0,0,0);
  /* box-shadow: inset 6px 0 4px -4px #999, inset -6px 0 4px -4px #999;
  -moz-box-shadow: inset 6px 0 4px -4px #999, inset -6px 0 4px -4px #999;
 -webkit-box-shadow: inset 6px 0 4px -4px #999, inset -6px 0 4px -4px #999; */
}

.logoitem {
  background-color: rgba(0,0,0,0);
  border-color: rgba(0,0,0,0);

}

.logocard {
  background-color: rgba(0,0,0,0);
  border-color: rgba(0,0,0,0);

}

.logoimg {
  max-width: 100%;
}


.savedatasettingscol {
  border-radius: 10px;
}

.savedatabutton:hover {
  /*background: linear-gradient(to bottom, #808080, #F5F5F5); border-width: 0px; color: white; /* TEST*/

    background-color: #004622!important;  border-color:#004622!important; /* TEST*/
    color: white;
}

.savedatabutton:active {
  /*background: linear-gradient(to bottom, #808080, #F5F5F5); border-width: 0px; color: white; /* TEST*/

  background-color: #006633!important;  border-color:#006633!important; /* TEST*/
  color: white;
}

.savedatabutton {
   /*background-color: rgba(0,0,0,0);  /* TEST, avant : white*/
   background-color: #006633!important;  border-color:#006633!important; /* TEST*/


 /* background: linear-gradient(to bottom, #F5F5F5, #808080); border-width: 0px; color: white; /* TEST*/



    font-weight: bold;
    height: 40px;
    font-size: 18px;

}

.rowDiv {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;

}

.saveddatatable {
  vertical-align: bottom;
}

.containercounter {
  width: 90%;
  height: 100%;
}

.counterrow {
  width: 100%;
  height: 100%;
}

.countercol {
  vertical-align: middle;
}

.mailtobutton:hover {
  /*background: linear-gradient(to bottom, #808080, #F5F5F5); border-width: 0px; color: white; /* TEST*/

    background-color: #004622!important;  border-color:#004622!important; /* TEST*/
    color: white;
}

.mailtobutton:active {
  /*background: linear-gradient(to bottom, #808080, #F5F5F5); border-width: 0px; color: white; /* TEST*/

  background-color: #006633!important;  border-color:#006633!important; /* TEST*/
  color: white;
}

.mailtobutton {
   /*background-color: rgba(0,0,0,0);  /* TEST, avant : white*/
   background-color: #006633!important;  border-color:#006633!important; /* TEST*/


 /* background: linear-gradient(to bottom, #F5F5F5, #808080); border-width: 0px; color: white; /* TEST*/



    font-weight: bold;
    height: 40px;
    font-size: 15px;
    margin: 3px;
}


.contactsendbutton:hover {
  /*background: linear-gradient(to bottom, #808080, #F5F5F5); border-width: 0px; color: white; /* TEST*/

    background-color: #004622!important;  border-color:#004622!important; /* TEST*/
    color: white;
}

.contactsendbutton:active {
  /*background: linear-gradient(to bottom, #808080, #F5F5F5); border-width: 0px; color: white; /* TEST*/

  background-color: #006633!important;  border-color:#006633!important; /* TEST*/
  color: white;
}

.contactsendbutton {
   /*background-color: rgba(0,0,0,0);  /* TEST, avant : white*/
   background-color: #006633!important;  border-color:#006633!important; /* TEST*/


 /* background: linear-gradient(to bottom, #F5F5F5, #808080); border-width: 0px; color: white; /* TEST*/



    font-weight: bold;
    height: 40px;
    font-size: 15px;
    margin: 3px;
}


.logos {


  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  box-shadow: inset 0 9px 0px 0px #F5F5F5,inset  0 -9px 0px 0px #F5F5F5, inset 12px 0 15px -4px rgba(0,0,0,.2), inset -12px 0 15px -4px rgba(0,0,0,.2);

}



.mainlogo {
  width:auto;
  max-height: 80px;
}
.partnerslogo {
  width:auto;
  max-height: 80px;
}
.sophiapartnerslogo {
  width:auto;
  max-height: 60px;
}





.logoeurope {
  width:80%;
  max-width: 400px;
}





.sophialogopartners {
  width:200px;
}
